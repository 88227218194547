import { useGetUtmSessionTags } from '@hooks/useUTMtags';
import Script from 'next/script';
import { Fragment, useEffect } from 'react';
import { getCachedRuntimeConfig } from 'utils/runtimeConfigUtils';
const cashedConfig = getCachedRuntimeConfig();

const { NEXT_PUBLIC_GTM_ID: CASHED_GTM_ID } = cashedConfig;

const UtmTagsCatcher = () => {
    const { utm_source, utm_medium, utm_campaign } = useGetUtmSessionTags();

    useEffect(() => {
        if (!global.window) return;

        window.dataLayer = window.dataLayer.filter(
            (el) => el['event'] !== 'utm_tags'
        );

        window.dataLayer.push({
            event: 'utm_tags',
            utm_source,
            utm_medium,
            utm_campaign,
        });
    }, [utm_source, utm_medium, utm_campaign]);

    return null;
};

const UtmTagsProvider = () => {
    const GTM_ID = process.env.NEXT_PUBLIC_GTM_ID || CASHED_GTM_ID;

    return (
        <Fragment>
            <Script id="GTMOnReadyScript">
                {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});
                    var f=d.getElementsByTagName(s)[0], j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';
                    j.async=true;
                    j.src= 'https://www.googletagmanager.com/gtm.js?id='+i+dl; 
                    '&gtm_auth=IJ-sB0Nt7mNFVoFrvsYtVw&gtm_preview=env-1&gtm_cookies_win=x';
                    f.parentNode.insertBefore(j,f); })(window,document,'script','dataLayer', '${GTM_ID}');
                `}
            </Script>

            <UtmTagsCatcher />
        </Fragment>
    );
};

export default UtmTagsProvider;
