import { ThemeProvider } from '@mui/material';
import { FlagProvider, IConfig } from '@unleash/proxy-client-react';
import getServerFlags from 'api/getServerFlags';
import MaintenanceBanner from 'components/MaintenanceBanner';
import { ToastContainer } from 'react-toastify';
import RuntimeConfigProvider from 'state/runtime-config-provider';
import useSWR from 'swr';
import { theme } from 'theme/theme';
import { getCachedRuntimeConfig } from 'utils/runtimeConfigUtils';
import UtmTagsProvider from './UtmTagsProvider';

interface ProvidersProps {
    children: React.ReactNode;
}

const cashedConfig = getCachedRuntimeConfig();

const {
    NEXT_PUBLIC_UNLEASH_FRONTEND_API_URL: CASHED_UNLEASH_URL,
    NEXT_PUBLIC_UNLEASH_FRONTEND_API_TOKEN: CASHED_UNLEASH_TOKEN,
    UNLEASH_APP_NAME: CASHED_UNLEASH_NAME,
} = cashedConfig;

const unleashConfig = {
    url: CASHED_UNLEASH_URL || 'https://not-used.com/unleash',
    clientKey: CASHED_UNLEASH_TOKEN || 'not-used',
    disableRefresh: true,
    appName: CASHED_UNLEASH_NAME || 'not-used',
} as IConfig;

const Providers: React.FC<ProvidersProps> = ({ children }) => {
    const serverFlagsFetcher = async () => {
        const { data } = await getServerFlags();
        return data;
    };

    const { data: flags } = useSWR('/flags/', serverFlagsFetcher, {
        revalidateOnFocus: false,
    });

    return (
        <RuntimeConfigProvider>
            <UtmTagsProvider />

            <FlagProvider config={unleashConfig}>
                <ThemeProvider theme={theme}>
                    <MaintenanceBanner {...flags} canBeClosed={true} />

                    {children}

                    <ToastContainer />
                </ThemeProvider>
            </FlagProvider>
        </RuntimeConfigProvider>
    );
};

export default Providers;
